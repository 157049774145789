.trms_condition h1 {
  @apply text-[40px] leading-[49px] md:text-[50px] md:leading-[59px] xl:text-[64px] xl:leading-[75px] mb-[16px];
}

.trms_condition h2 {
  @apply text-[26px] leading-[38px] md:text-[30px] md:leading-[42px] xl:text-[40px] xl:leading-[64px] mt-[40px] md:mt-[48px];
}

.trms_condition h4 {
  @apply text-[20px] leading-[30px] xl:text-[24px] xl:leading-[32px] mt-[16px];
}

.trms_condition p,
.trms_condition a,
.trms_condition li {
  @apply text-[16px] leading-[24px] md:text-[18px] md:leading-[30px] xl:text-[20px] xl:leading-[32px] mt-[8px];
}
.trms_condition li {
  list-style: circle;
}

.trms_condition ul {
  @apply pl-[30px];
}

._h1 {
  @apply text-[#272a3f] font-medium text-[clamp(42px,calc(70/1340*100vw),70px)] leading-[1] text-center max-w-[1120px] mx-auto mt-[30px];
}

._h1_big {
  @apply text-[#272a3f] font-medium text-[clamp(52px,calc(100/1340*100vw),100px)] leading-[0.95] text-center;
}

._h1_top {
  @apply text-[#4c3cff] font-medium  text-[16px] text-center max-w-[343px];
}

._p {
  @apply max-w-[650px] text-[#3b4147] text-center font-[300] text-[clamp(16px,calc(20/1340*100vw),20px)] leading-[1.5] mx-auto mt-[24px];
}
