.chat-screen {
  position: fixed;
  bottom: 0;
  right: 10px;
  width: 300px;
  height: 500px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}

.chat-screen.open {
  transform: translateY(0);
}

.chat-header {
  background-color: #007bff;
  color: white;
  padding: 10px;
  text-align: right;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
}

.chat-body {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.chat-message {
  padding: 10px;
  border-radius: 10px;
  max-width: 70%;
}

.chat-message.user {
  background-color: #007bff;
  color: white;
  align-self: flex-end;
}

.chat-message.bot {
  background-color: #f1f1f1;
  align-self: flex-start;
}

.chat-input {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ddd;
}

.chat-input input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 20px;
  margin-right: 10px;
}

.active-send {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.inactive-send {
  padding: 10px;
  background-color: gray;
  color: white;
  border: none;
  border-radius: 20px;
}

.wait-info {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: white;
  /* background-color: #007bff; */
}

/* .chat-input button:hover {
  background-color: #0056b3;
} */

@media only screen and (min-width: 576px) {
  .chat-screen {
    width: 500px;
  }
}

@media only screen and (min-width: 786px) {
  .chat-screen {
    width: 700px;
  }
}
