.general-chat-screen {
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 10px;
}

.general-chat-screen.open {
  transform: translateY(0);
}

.chat-header {
  background-color: #007bff;
  color: white;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
}

.chat-body {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.chat-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.chat-message {
  padding: 10px;
  border-radius: 10px;
  max-width: 70%;
}

.chat-item:last-child {
  margin-bottom: 10px;
}

.timestamp {
  font-size: 0.75em;
  color: gray;
  margin-top: 5px;
  align-self: flex-start;
}

.chat-message.user {
  background-color: #007bff;
  color: white;
  align-self: flex-end;
}

.chat-message.bot {
  background-color: #f1f1f1;
  align-self: flex-start;
}

.chat-input {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ddd;
}

.chat-input input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 20px;
  margin-right: 10px;
}

.active-send {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.inactive-send {
  padding: 10px;
  background-color: gray;
  color: white;
  border: none;
  border-radius: 20px;
}

.wait-info {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: white;
}

@media only screen and (min-width: 576px) {
  .general-chat-screen {
    width: 500px;
  }
}

@media only screen and (min-width: 786px) {
  .general-chat-screen {
    width: 700px;
  }
}
