@tailwind base;
@tailwind components;

.fieldCss {
  @apply bg-white w-full h-10 rounded-md focus:outline-none text-black border border-[#E9E7F4] px-4 py-1.5 text-base disabled:text-[#BCBCC3];
}

@tailwind utilities;

::-webkit-scrollbar {
  display: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.button_style {
  width: max-content;
}

.uploaded-document-card__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.uploaded-document__card {
  display: flex;
  gap: 10px;
  align-items: center;
  /* border: 0.1px dotted gray; */
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #eaf0ff;
  font-size: 12px;
  font-weight: 600;
}

.profile_image {
  width: 100px;
  height: 100px;
}

.pagination_box {
  display: flex !important;
  justify-content: center;
  margin: 30px 0;
}

@media only screen and (min-width: 476px) {
  .uploaded-document-card__container {
    grid-template-columns: repeat(3, 1fr);
  }
}
