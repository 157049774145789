.pagination_container {
  display: flex;
  gap: 5px !important;
  justify-content: center;
  margin-bottom: 20px;
}

.pagination_container button {
  padding: 8px 10px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 2px;
  color: gray;
  font-size: 14px;
}

.pagination_container button.active {
  background-color: #0070de;
  color: white;
}
